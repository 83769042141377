import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _598ba18f = () => interopDefault(import('../client/pages/kits/index.vue' /* webpackChunkName: "pages/kits/index" */))
const _a4e7ebc6 = () => interopDefault(import('../client/pages/lojas/index.vue' /* webpackChunkName: "pages/lojas/index" */))
const _bc42ed9a = () => interopDefault(import('../client/pages/mapa-do-site/index.vue' /* webpackChunkName: "pages/mapa-do-site/index" */))
const _55d14291 = () => interopDefault(import('../client/pages/minha-conta/index.vue' /* webpackChunkName: "pages/minha-conta/index" */))
const _77867ecc = () => interopDefault(import('../client/pages/motos/index.vue' /* webpackChunkName: "pages/motos/index" */))
const _0c4d2a33 = () => interopDefault(import('../client/pages/reset/index.vue' /* webpackChunkName: "pages/reset/index" */))
const _c1eb8194 = () => interopDefault(import('../client/pages/veiculos/index.vue' /* webpackChunkName: "pages/veiculos/index" */))
const _5d88c3d2 = () => interopDefault(import('../client/pages/checkout/carrinho/index.vue' /* webpackChunkName: "pages/checkout/carrinho/index" */))
const _5832c9f9 = () => interopDefault(import('../client/pages/checkout/confirmacao/index.vue' /* webpackChunkName: "pages/checkout/confirmacao/index" */))
const _42ad368f = () => interopDefault(import('../client/pages/checkout/pagamento/index.vue' /* webpackChunkName: "pages/checkout/pagamento/index" */))
const _70ef9fcc = () => interopDefault(import('../client/pages/minha-conta/enderecos/index.vue' /* webpackChunkName: "pages/minha-conta/enderecos/index" */))
const _2c1d5630 = () => interopDefault(import('../client/pages/minha-conta/meus-dados/index.vue' /* webpackChunkName: "pages/minha-conta/meus-dados/index" */))
const _7a165890 = () => interopDefault(import('../client/pages/minha-conta/pedidos/index.vue' /* webpackChunkName: "pages/minha-conta/pedidos/index" */))
const _551d0f28 = () => interopDefault(import('../client/pages/usuario/cadastro/index.vue' /* webpackChunkName: "pages/usuario/cadastro/index" */))
const _b64ec428 = () => interopDefault(import('../client/pages/usuario/login/index.vue' /* webpackChunkName: "pages/usuario/login/index" */))
const _37de87f4 = () => interopDefault(import('../client/pages/checkout/confirmacao/pix.vue' /* webpackChunkName: "pages/checkout/confirmacao/pix" */))
const _9eb5c4d6 = () => interopDefault(import('../client/pages/produto/_id/_.vue' /* webpackChunkName: "pages/produto/_id/_" */))
const _0cb509b2 = () => interopDefault(import('../client/pages/kit/_id/_.vue' /* webpackChunkName: "pages/kit/_id/_" */))
const _457b8ef4 = () => interopDefault(import('../client/pages/lista/_.vue' /* webpackChunkName: "pages/lista/_" */))
const _3f938f9e = () => interopDefault(import('../client/pages/informacoes-gerais/_.vue' /* webpackChunkName: "pages/informacoes-gerais/_" */))
const _572eb9b3 = () => interopDefault(import('../client/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/kits",
    component: _598ba18f,
    name: "kits"
  }, {
    path: "/lojas",
    component: _a4e7ebc6,
    name: "lojas"
  }, {
    path: "/mapa-do-site",
    component: _bc42ed9a,
    name: "mapa-do-site"
  }, {
    path: "/minha-conta",
    component: _55d14291,
    name: "minha-conta"
  }, {
    path: "/motos",
    component: _77867ecc,
    name: "motos"
  }, {
    path: "/reset",
    component: _0c4d2a33,
    name: "reset"
  }, {
    path: "/veiculos",
    component: _c1eb8194,
    name: "veiculos"
  }, {
    path: "/checkout/carrinho",
    component: _5d88c3d2,
    name: "checkout-carrinho"
  }, {
    path: "/checkout/confirmacao",
    component: _5832c9f9,
    name: "checkout-confirmacao"
  }, {
    path: "/checkout/pagamento",
    component: _42ad368f,
    name: "checkout-pagamento"
  }, {
    path: "/minha-conta/enderecos",
    component: _70ef9fcc,
    name: "minha-conta-enderecos"
  }, {
    path: "/minha-conta/meus-dados",
    component: _2c1d5630,
    name: "minha-conta-meus-dados"
  }, {
    path: "/minha-conta/pedidos",
    component: _7a165890,
    name: "minha-conta-pedidos"
  }, {
    path: "/usuario/cadastro",
    component: _551d0f28,
    name: "usuario-cadastro"
  }, {
    path: "/usuario/login",
    component: _b64ec428,
    name: "usuario-login"
  }, {
    path: "/checkout/confirmacao/pix",
    component: _37de87f4,
    name: "checkout-confirmacao-pix"
  }, {
    path: "/produto/:id?/*",
    component: _9eb5c4d6,
    name: "produto-id-all"
  }, {
    path: "/kit/:id?/*",
    component: _0cb509b2,
    name: "kit-id-all"
  }, {
    path: "/lista/*",
    component: _457b8ef4,
    name: "lista-all"
  }, {
    path: "/informacoes-gerais/*",
    component: _3f938f9e,
    name: "informacoes-gerais-all"
  }, {
    path: "/",
    component: _572eb9b3,
    name: "index"
  }],

  parseQuery: function(query) {
			const qs = require("qs")

			return qs.parse(query)
		},
  stringifyQuery: function(query) {
			const qs = require("qs")

			const result = qs.stringify(query)

			return result ? `?${result}` : ""
		},
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
